.navbar {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--lighterBackground);
  position: relative;
}

.navbar-logo-text {
  font-size: 22px;
  font-weight: bold;
  color: var(--textWhite);
  margin-left: 10px;
}

.navbar-logo-icon {
  width: 50px;
  height: 50px;
  border: 2px solid var(--darkOutline);
  border-radius: 10px;
}

.navbar-help-icon {
  fill: var(--textWhite);
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  margin-bottom: -5px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.navbar-help-icon:hover {
  transform: scale(1.1);
}

.navbar-moves-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
}

.navbar-rem-moves {
  font-size: 20px;
  color: var(--textWhite);
  margin: 0;
  font-weight: 600;
  background: var(--darkBackground);
  padding: 8px 10px;
  border: 2px solid var(--darkOutline);
  border-radius: 10px;
  transition: all 0.25s ease;
}
.navbar-rem-moves.warning {
  background: rgba(255, 0, 0, 0.168);
  color: rgb(218, 178, 178);
  border: 2px solid rgba(218, 178, 178, 0.75);
}

.navbar-rem-text {
  font-size: 18px;
  color: var(--textWhite);
  margin: 0;
  margin-right: 8px;
}

@media only screen and (min-width: 400px) and (max-width: 700px) {
  .navbar-rem-text {
    font-size: 15px;
    width: 70px;
    text-align: right;
    margin-right: 12px;
  }
}

@media screen and (min-width: 300px) and (max-width: 580px) {
  .navbar-rem-text {
    display: none;
  }
}

@media screen and (min-width: 252px) and (max-width: 300px) {
  .navbar-rem-text {
    display: none;
  }
}

@media screen and (max-width: 252px) {
  .navbar-moves-container {
    display: none;
  }
  .navbar-help-icon {
    display: none;
  }
}
