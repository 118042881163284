.main-screen {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  opacity: 1;
  filter: blur(0);
  transition: all 0.35s ease;
}

.main-screen.loading {
  opacity: 0;
  filter: blur(15px);
}

.current-word-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  border: solid 3.5px var(--lighterBackground);
  border-radius: 12px;
  width: fit-content;
  margin-bottom: 20px;
}

.current-word-letter {
  font-size: 26px;
  margin: 0 0.5px;
}

.main-screen-game-container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
}

.ms-column-padding {
  width: calc(2 * (68px));
  height: calc(6 * (70px));
}

.letters-roller-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}

@media only screen and (min-width: 385px) and (max-width: 510px) {
  .ms-column-padding {
    width: calc(2 * (54.8px));
    height: calc(6 * (58px));
  }
  .current-word-letter {
    font-size: 20px;
  }
  .current-word-container {
    border-width: 3px;
    border-radius: 10px;
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 310px) and (max-width: 385px) {
  .ms-column-padding {
    width: calc(2 * (43.6px));
    height: calc(6 * (46px));
  }
  .current-word-letter {
    font-size: 17px;
  }
  .current-word-container {
    border-width: 2.5px;
    border-radius: 10px;
    margin-bottom: 18px;
    padding: 8px 12px;
  }
}

@media screen and (min-width: 252px) and (max-width: 310px) {
  .ms-column-padding {
    width: calc(2 * (36.5px));
    height: calc(6 * (38px));
  }
  .current-word-letter {
    font-size: 17px;
  }
  .current-word-container {
    border-width: 2.5px;
    border-radius: 10px;
    margin-bottom: 18px;
    padding: 8px 12px;
  }
}

@media screen and (max-width: 252px) {
  .current-word-container {
    display: none;
  }
  .main-screen-game-container {
    display: none;
  }
}
