.app-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: var(--darkBackground);
  color: var(--textWhite);
  min-height: 590px;
  overflow-x: hidden;
}

:root {
  background-color: var(--darkBackground);
  overflow-x: hidden;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
