.notification-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--darkerBlue);
  opacity: 0;
  transition: all 0.5s ease;
  width: fit-content;
  height: fit-content;
  z-index: 9999;
  border-radius: 8px;
}

.notification-container.visible {
  opacity: 1;
  top: 30px;
}

.notification-container > p {
  margin: 12px 18px;
  font-size: 18px;
  color: var(--textWhite);
}
