.modal-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
}

.modal-background.active {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  background-color: var(--lighterBackground);
  padding: 20px 25px 25px 25px;
  border-radius: 12px;
  position: relative;
  min-width: 200px;
  width: 40vw;
  max-width: 400px;
  z-index: 10;
  opacity: 1;
  filter: blur(0);
  transition: all 0.35s ease;
}

.modal-content.loading {
  opacity: 0;
  filter: blur(15px);
}

.modal-content.help {
  max-width: 800px;
  width: 70vw;
}

.modal-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px;
  fill: var(--textWhite);
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.25s ease;
  pointer-events: all;
}

.modal-close-icon:hover {
  transform: scale(1.1);
}

.modal-content h1 {
  margin: 0 0 16px 0;
  font-size: 24px;
  color: var(--textWhite);
  text-align: center;
}

.modal-content p {
  margin: 0;
  font-size: 19px;
  color: var(--textWhite);
  text-align: center;
}

.modal-ready-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-ready-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.modal-ready-container > p {
  font-size: 17px;
  margin-bottom: 8px;
}

.modal-start-timer-btn,
.modal-open-help-btn {
  max-width: 200px;
  background-color: var(--darkerGreen);
  border-radius: 8px;
  font-size: 16px;
  color: var(--textWhite);
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease;
  margin: 0 6px;
  margin-top: 10px;
  border: none;
  padding: 9px 16px;
  font-weight: bold;
}

.modal-start-timer-btn:hover,
.modal-open-help-btn:hover {
  transform: scale(1.035);
}

.modal-open-help-btn {
  background-color: transparent;
  border: solid 2px var(--darkOutline);
  padding: 7px 14px;
  font-weight: normal;
}

.modal-help-column-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.modal-help-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: solid 2px var(--darkOutline);
  border-radius: 12px;
  margin: 0 6px;
  position: relative;
  background: var(--darkBackground);
  padding-top: 25px;
}

.modal-help-column-num {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--darkOutline) !important;
  text-align: center;
  margin: 0;
  width: 26px;
  height: 26px;
  font-size: 16px !important;
  border-radius: 100px;
  border: solid 2px var(--darkOutline);
  background: var(--darkBackground);
  position: absolute;
  top: -15px;
  font-weight: bold;
}

.modal-help-column-img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}

.modal-help-desc {
  font-size: 15px !important;
  margin: 20px 10px !important;
  text-align: center;
}

.win-modal-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-top: solid 2px var(--darkOutline);
  margin-top: 16px;
  padding-top: 16px;
}

.win-modal-share-button {
  font-size: 18px;
  margin: 0;
  background: var(--darkerBlue);
  padding: 13px 18px;
  height: fit-content;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--darkerBlue);
  font-weight: bold;
  color: var(--textWhite);
}

.win-modal-share-button.copied {
  color: var(--primaryBlue);
  background: var(--lighterBackground);
  border: solid 2px var(--primaryBlue);
}

.win-modal-share-button:hover {
  transform: scale(1.05);
}

.win-modal-bottom-container > div {
  margin-right: 24px;
  width: 150px;
}

.win-modal-bottom-container h4 {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

.time-till-next {
  margin: 0 !important;
  font-size: 16px !important;
  margin-top: 6px !important;
  font-weight: bold;
  font-size: 26px !important;
}

.modal-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.modal-stat-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}

.modal-stat-block > h2 {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.modal-stat-block > p {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  margin-top: 3px;
}

@media only screen and (min-width: 650px) and (max-width: 800px) {
  .modal-help-desc {
    font-size: 13px !important;
    margin: 15px 10px !important;
  }
  .modal-content.win {
    width: 75vw;
  }
  .win-modal-bottom-container > div {
    margin-right: 24px;
    width: 150px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 650px) {
  .modal-help-desc {
    font-size: 13px !important;
    margin: 15px 10px !important;
  }
  .modal-help-column-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .modal-help-column {
    flex: 1 1 150px;
    height: 30vh;
  }
  .modal-help-column:nth-child(3) {
    margin-top: 12px !important;
  }
  .modal-help-column-img {
    width: 60%;
    height: 80%;
    object-fit: contain;
  }
  .modal-content.win {
    width: 75vw;
  }
  .win-modal-bottom-container > div {
    margin-right: 24px;
    width: 150px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .modal-help-desc {
    font-size: 14px !important;
    margin: 15px 20px !important;
  }
  .modal-help-column-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .modal-help-column {
    width: 100%;
    height: fit-content;
    flex-direction: row;
    padding: 10px 0 16px 24px;
    margin: 5px 0;
  }
  .modal-help-column-img {
    width: 35%;
    height: 100%;
    object-fit: contain;
  }
  .modal-help-column-num {
    left: -16px;
    top: calc(50% - 13px);
  }
  .modal-stat-block > p {
    font-size: 12px;
  }
  .modal-content.win {
    width: 75vw;
  }
  .win-modal-bottom-container > div {
    margin-right: 10px;
    width: 120px;
  }
}
