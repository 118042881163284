body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --primaryBlue: #64c1fb;
  --secondaryGreen: #84edc1;
  --textWhite: #dfe7ef;
  --darkBackground: #1f272b;
  --darkOutline: #404c55;
  --lighterBackground: #2c363b;
  --darkerBlue: #4d8db5;
  --darkerGreen: #519a7c;
}
