.letters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  transition: all 0.25s ease;
}

.letter {
  width: 60px;
  height: 60px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--lighterBackground);
  border-radius: 10px;
  font-size: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.25s ease;
}

.letter.selected {
  background: var(--darkerBlue);
}

.letter[id="used"],
.letter[id="used"].selected {
  background: var(--darkerGreen);
}

.arrow-left,
.arrow-right {
  width: 65px;
  height: 65px;
  fill: var(--lighterBackground);
  transition: all 0.3s ease;
  cursor: pointer;
}

.arrow-left.hidden,
.arrow-right.hidden {
  opacity: 0;
  pointer-events: none;
}

.arrow-left:hover,
.arrow-right:hover {
  fill: var(--darkerBlue);
}

.arrow-left:hover {
  transform: translateX(-5px) rotate(180deg);
}
.arrow-right:hover {
  transform: translateX(5px);
}

.arrow-left {
  transform: rotate(180deg);
}

@media only screen and (min-width: 385px) and (max-width: 510px) {
  .letter {
    width: 50px;
    height: 50px;
    margin: 4px;
    border-radius: 10px;
    font-size: 1.75rem;
  }

  .arrow-left,
  .arrow-right {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 310px) and (max-width: 385px) {
  .letter {
    width: 40px;
    height: 40px;
    margin: 3px;
    border-radius: 9px;
    font-size: 1.4rem;
  }

  .arrow-left,
  .arrow-right {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 310px) {
  .letter {
    width: 32px;
    height: 32px;
    margin: 3px;
    border-radius: 6px;
    font-size: 1.2rem;
  }

  .arrow-left,
  .arrow-right {
    width: 32px;
    height: 32px;
  }
}
